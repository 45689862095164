import styles from './GGIValues.module.less';

const GGIValues = (props) => {
  return (
    <div className={styles.ggivalues}>
      <img
        src='/ggiValues.jpg'
        alt='GGI Values'
        className={styles.ggivalues__img}
      />
    </div>
  );
};

export default GGIValues;
