import { useContext } from 'react';
import UserContext from '../../Context/user-context';
import styles from './Loyalty.module.less';

const Loyalty = (props) => {
    const userCtx = useContext(UserContext);
    const { loading, data } = userCtx;

    return (
        <div className='container'>
            {loading ? null : data ? (
            <>
                <div className='box'>
                    
                    <h4>Summary of the Document</h4>
                    TL;DR :<br/>
                    <p>
                        1. You will get INR 2,000 after every new successful recommendation of onboarded GGI
                        Scholar/Fellow
                    </p>
                    <p>
                        2. You will get an extended 10-weeks of free access to <a style={{color:'blue'}} href='https://www.schrodingerbyggi.org/'  target="_blank" rel="noreferrer">Schrodinger and Trinity</a> by GGI. Please note,
                        we are always increasing content and cases on both GGI tech learning platforms.
                    </p>
                    <b>*with conditions applied. Please read carefully.</b>
                </div>

                <div className='box'>
                    <p>Hey There,
                        It is often said that if you have nothing in your life, but you have at least one person that cares for you
                        unconditionally, it will do wondersfor yourself-esteem. For you that one person is the entire community
                        at Global Governance Initiative.
                    </p>
                    <p>
                        At the same time, it is also important that we do our best to ensure that this community stands with, for,
                        and by each other for the rest of our lives, not just a few beautiful months.
                    </p>
                    <p>
                        In the past few months, the number of applications for the Scholars Program has dramatically increased.
                        However, our selection percentage has remained less than 10%. Fortunately, it allows us to choose
                        people from a large pool of external candidates but it's not humanly possible for our admissions
                        committee to always make the right choice while selecting or rejecting candidates.
                    </p>
                    <p>
                        In order to ensure that this community attracts not just accomplished and diverse people, but also
                        people with the right ethics- we have carefully decided to give preference to those applicants who will
                        be internally recommended by our existing scholars. We trust you, we believe in you, and we are sure
                        that you will be the flag bearers of the ethical standards, diversity, inclusive, and respectful community
                        that we wish to create at Global Governance Initiative.
                    </p>
                    <p>
                        We hope you’d use this power judiciously. With great power comes great responsibility, indeed.
                    </p>
                    <h2 className={data.program[0].name === "ALT IIT Scholar" ? styles.greenText : styles.red}><br/>*Now? What’s in it for you here?</h2>
                    <br/>
                    <p>
                        In addition to the unique opportunity to have a say in the admissions process. Our team has also
                        designed a mechanism that will provide impeccable learning opportunities in two ways :
                    </p>
                    <p>
                        <b>1. Monetary Benefit of INR 2,000/per successful recommendation:</b> As soon as your another
                        recommended candidate is onboarded successfully as a Scholar/fellow then you will be given
                        monetary benefit of INR 2,000.
                    </p>
                    <p>
                        And as soon as your second recommended candidate is onboarded successfully as a scholar/fellow, you
                        will be given another INR 2,000, so on and so forth. This is being done to ensure that you maximize
                        wealth sharing.
                    </p>
                    <p>
                        <b>2. Schrodinger and Trinity Access-</b> You will receive an extended 10-weeks of accessto the <a style={{color:'blue'}} href='https://www.schrodingerbyggi.org/'  target="_blank" rel="noreferrer">Schrödinger and
                        Trinity</a> by GGI upon a match of at least 1 new scholar. This would also allow you to pivot into your dream
                        roles by connecting with speakers - who are usually industry veterans.
                    </p>
                    <p className={data.program[0].name === "ALT IIT Scholar" ? styles.greenText : styles.red}>
                        Do fill out this <u><a href='https://docs.google.com/forms/d/e/1FAIpQLSf8xeEH5Obct8lfeX6Fp6F1KK00gaCd3vJkQicjXdm9-os9Dg/viewform' target="_blank" rel="noreferrer">form</a></u> - if your recommended name matches with the applicant's name on the GGI website
                        <a href="https://www.globalgovernanceinitiative.org/ggi-scholars"  target="_blank" rel="noreferrer"> form </a>, then our team will automatically reach out to you.
                    </p>
                    <p>
                        Let us make sure that we meet, network, and thrive together. :)
                    </p>
                </div>


            </>
            ) : null} 
        </div>
    );
};

export default Loyalty;
