import { createContext, useState } from 'react';
import axios from '../helpers/axios-instance';

const UserContext = createContext({
  user: null,
  loading: null,
  error: null,
  getMyData: () => {},
  setProfile:(profile)=>{},
  getProfile:()=>{},
  fetchallUsers:()=>{},
  logout: () => {},
});

export const UserContextProvider = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [img, setImg]=useState();
  const [allusers, setAllusers]=useState({});
  const getMyData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/v1/users/getme`, {
        withCredentials: true,
      });
      setData(data.data[0]);
      setLoading(false);
    } catch (err) {
      // console.log(err.response);
      setError(err.response && err.response.data || 'Error');
      console.error(err);
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------------------
  const setProfile= async(profile)=>{  //uploads img to cloud n db
    setLoading(true);
    try{
      // console.log(profile);   this is actually a file
      //adding to cloud
      const dataInstance = new FormData();
      dataInstance.append('file', profile);
      dataInstance.append('upload_preset', 'LMSggi');
      dataInstance.append('cloud_name', 'wigglyggi');
      fetch('https://api.cloudinary.com/v1_1/wigglyggi/image/upload', {
        method: "post",
        body:dataInstance
      })
      .then(res=>res.json())
      .then(data=>{
        // console.log(data.url);
        const profile=data.url;
        axios.put('/v1/users/setProfileImage',{profile},{withCredentials:true});//adding to baackend

      })
      .catch(err=>{
        // console.log(err);
      })
      setLoading(false);
    }
    catch(err){
      // console.log(err.response.data);
      setLoading(false);      
    }
  }
  // ---------------------------------------------------
  const getProfile= async()=>{ //fetches img url from db
    await axios.get('/v1/users/getProfileImage',{withCredentials:true})
    .then(res=>{
      setImg(res.data.pic);
    })
  };
  //----------------------fetchall --------------------------
  const fetchAllUsers= async()=>{

    await axios.get('/v1/users/fetchAllUsers',{withCredentials:true})
    .then(res=>{
      // console.log(res.data);
      setAllusers(res.data);
    }).catch(err=>{
      // console.log(err.response.data);
    }
    )
  }

  const logout = async () => {
    try {
      await axios.get(`/v1/users/logout`, {
        withCredentials: true,
      });
      window.location.reload();
    } catch (err) {
      // console.log(err.response);
    }
  };

  const context = {
    data,
    loading,
    error,
    img,
    allusers,
    getMyData,
    setProfile,
    getProfile,
    fetchAllUsers,
    logout,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
