import { Button } from 'antd';
import { useContext } from 'react';
import UserContext from '../../Context/user-context';
import styles from './Career.module.less';
import formdata from './formData.json';
const Form = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  //   let career;
  //   if (data) {
  //     career = data.program[0].career;
  //   }

  return (
    <div className='container'>
      {loading ? null : formdata ? (
        <form>
          <div className='box'>
            <h2 className='box-title'>{formdata.title}</h2>
            <h4 >{formdata.Description1}</h4>
          </div>
          <div className='box'>
            <h4 className='box-title'>{formdata.Description2}</h4>
            <li>Gender ? If available once in three weeks to engage in case buddy sessions on Schrondinger</li>
            <fieldset className={styles.list}>
              <input type="radio" />
              <label >Male</label>
              <br />
              <input type="radio" />
              <label >Female</label>
            </fieldset>
            <li>What is your latest qualification? If available once in three weeks to engage in case buddy sessions on Schrondinger</li>
            <fieldset className={styles.list}>
              <input type="radio" />
              <label >Graduate or Pursuing</label>
              <br />
              <input type="radio" />
              <label >Post-Graduate or Pursuing</label>
            </fieldset>
            <li>What is your background? If available once in three weeks to engage in case buddy sessions on Schrondinger</li>
            <fieldset className={styles.list}>
              <input type="radio" />
              <label >Engineering</label>
              <br />
              <input type="radio" />
              <label >Non Engineering</label>
            </fieldset>
            {/* <p>{career[1].desc}</p> */}
            {/* <ul className={styles.list}>
              {formdata.questions.list.map((item, index) => (
                <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul> */}
            {/* <ul>
              {career[1].other.map((item, index) => (
                <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul> */}
          </div>
          <div className='box'>
            <h4 className='box-title'>{formdata.Description3}</h4>
            <div className={styles.list}>
              {formdata.masterclasses.map((item) => (
                <div>
                  <input type="radio" value={item} required /> &nbsp;
                  <label >{item}</label>
                </div>
              ))}
            </div>
          </div>
          <Button type='primary' size='large'>Submit</Button>
        </form>
      ) : null}
    </div>
  );
};

export default Form;

