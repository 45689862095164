import { useContext } from "react";
import UserContext from "../../Context/user-context";
import styles from "./Career.module.less";

const Career = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  let career;
  if (data) {
    career = data.program[0].career;
  }

  return (
    <div className="container">
      {loading ? null : data ? (
        <>
          {data.level >= 1 && (
            <div className="box">
              <h3 className="box-title">
                <span className={styles.highlight}>{career[0].title}</span>
              </h3>
              <p>
                The <strong>Office of Career Services</strong> at
                {data.program[0].name === "Global Tech Initiative Scholar (6 months)" || data.program[0].name === "GTI Feynman Fellow" || data.program[0].name === "Global Tech Initiative Scholar (4 months)" ? "  GTI " : " GGI "}
                is partnering with the top management consulting, public policy,
                and product organizations to bridge the gap between you and your
                dream jobs!
              </p>

              {career[0].desc.map((descItem, index) => (
                <div key={index} className="box">
                  <p
                    className={styles.highlight}
                    dangerouslySetInnerHTML={{ __html: descItem.intro }}
                  ></p>
                  <p dangerouslySetInnerHTML={{ __html: descItem.about }}></p>
                  <p dangerouslySetInnerHTML={{ __html: descItem.details }}></p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: descItem.applicationlink,
                    }}
                  ></p>
                  <p>Please find below the JDs here</p>
                  <p dangerouslySetInnerHTML={{ __html: descItem.jds }}></p>
                  <p>
                    Please ensure that you have added
                    {data.program[0].name === "ALT IIT Scholar"
                      ? " Global Tech Initiative "
                      : " the Global Governance Initiative "}
                    to your resume/CV.
                  </p>
                  <strong>
                    Do not apply if you do not meet the eligibility criteria and
                    relevant experience mentioned in the JD.
                  </strong>
                </div>
              ))}
            </div>
          )}

          <div className="box">
            <h3 className="box-title">{career[1].title}</h3>
            {career[1].desc2 ? (
              <p dangerouslySetInnerHTML={{ __html: career[1].desc2 }}></p>
            ) : (
              <p></p>
            )}
            {career[1].careerImg ? (
              <img
                style={{ width: "100%", marginBottom: "4vw" }}
                src={career[1].careerImg}
              />
            ) : (
              <p></p>
            )}

            <p dangerouslySetInnerHTML={{ __html: career[1].desc }}></p>
          </div>

          {data.program[0].name === "ALT IIT Scholar"
                      ? null
                      :  <div className="box">
                      <h3 className="box-title">{career[2].title}</h3>
                      {/* <p>{career[1].desc}</p> */}
                      <ul className={styles.list}>
                        {career[2].list.map((item, index) => (
                          <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
                          // <li key={index}>{item}</li>
                        ))}
                      </ul>
                      {/* <ul>
                        {career[1].other.map((item, index) => (
                          <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                      </ul> */}
                    </div>}
         
          {data.program[0].name === "ALT IIT Scholar" ? null : <div className="box">
            <h3 className="box-title">{career[3].title}</h3>
            <p>{career[3].desc}</p>
            <ul className={styles.list}>
              {career[3].list.map((item, index) => (
                <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul>
            <ul>
              {career[3].other.map((item, index) => (
                <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul>
          </div>}
          

          {data.program[0].name === "ALT IIT Scholar"
                      ?  <div className="box">
                      <h3 className="box-title">
                        Global Tech Initiative Office of Career Services (OCS) Ethos, FAQs, and
                        Guidelines
                      </h3>
                      <p><strong>1. At what stage should Scholars apply to OCS opportunities?</strong></p>
                      <p>
                        Global Tech Initiative Scholars should apply to OCS opportunities ONLY after
                        completing Level 1 (Completing 5 masterclasses).
                      </p>
                      <p>
                        <strong>2. Please ensure you all have prepared a good quality CV</strong> as you
                        apply. To help you additionally, we have carefully curated a list of external source for your <strong>resume enhancement,</strong> we would
                        encourage you to go through the same before submitting your
                        applications:
                      </p>
                      <ul>
                        <li>
                          <a
                            href="https://dtech.duke.edu/product-management-resumes"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://dtech.duke.edu/product-management-resumes
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://github.com/jakegut/resume"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://github.com/jakegut/resume
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://medium.com/product-manager-hq/the-ultimate-product-manager-resume-guide-c8ea3530ed82"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://medium.com/product-manager-hq/the-ultimate-product-manager-resume-guide-c8ea3530ed82
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.hiration.com/blog/machine-learning-resume/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://www.hiration.com/blog/machine-learning-resume/
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://medium.com/@mygreatlearning/machine-learning-resume-sample-how-to-build-a-strong-ml-resume-3d402261f25a"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://medium.com/@mygreatlearning/machine-learning-resume-sample-how-to-build-a-strong-ml-resume-3d402261f25a
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.techinterviewhandbook.org/system-design/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://www.techinterviewhandbook.org/system-design/
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.resumepilots.com/blogs/career-advice/crafting-for-financial-software-development-roles"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://www.resumepilots.com/blogs/career-advice/crafting-for-financial-software-development-roles
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://medium.com/swlh/writing-a-resume-the-step-by-step-recipe-29a0cc4e5dc8"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://medium.com/swlh/writing-a-resume-the-step-by-step-recipe-29a0cc4e5dc8
                          </a>
                        </li>
                      </ul>
                      <p>
                        <strong>3.</strong> OCS by Global Tech Initiative has been created to help you pivot into your
                        dream roles. Having said this, by no means do we want to create
                        another typical "placement mindset and culture" in Global Tech Initiative. We at
                        Global Tech Initiative have always believed in teaching you all how to fish
                        rather than giving you fish to eat. You are very much encouraged
                        to find and get your own jobs.
                      </p>
                      <p>
                        While we highly advocate applying for this employer, <strong>Office of
                        Career Services benefits should be over and above your job hunt.</strong>
                        That is how the top 1% of people are created in any community.
                      </p>
                    </div>
                      : null} 
         
        </>
      ) : null}
    </div>
  );
};

export default Career;
