import { useContext } from 'react';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import UserContext from '../../Context/user-context';
import styles from './Network.module.less';

const Network = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  let network;
  let programType;

  if (data) {
    network = data.program[0].network;
    programType = data.program[0].code;
  }
  console.log('data:', data);
  console.log('programType:', programType);

  return (
    <div className='container'>
      {loading ? null : data ? (
        <>
          <div className='box'>
           {(programType === 8 || programType === 14)? ( 
            <h3 className='box-title'>LinkedIn</h3>
            ) : (
              <h3 className='box-title'>Whatsapp Group and LinkedIn</h3>
            )}
            {network.other.map((item, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
            ))}
          </div>
          {network.wiggly && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'>Wiggle with Wiggly</h3>
              <p>{network.wiggly}</p>
              <img
                src='/wiggly.png'
                alt='Wiggly'
                className={styles.item__img}
              />
              <div className={styles.item__btn}>
                <Button
                  type='primary'
                  icon={<LogoutOutlined rotate='-45' />}
                  href='https://www.wiggly.org.in'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Wiggly
                </Button>
              </div>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Network;
