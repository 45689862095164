import { useEffect, useState } from 'react';
import axios from '../../helpers/axios-instance';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout/Layout';
import Spinner from '../UI/Spinner/Spinner';

const WithAuth = (props) => {
  const [checking, setChecking] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axios.get(`/v1/users/checkAuth`, {
          withCredentials: true,
        });
        setChecking(false);
      } catch (error) {
        navigate('/');
      }
    };
    checkAuth();
  }, [navigate]);

  if (checking) {
    return <Spinner center />;
  }

  return <Layout>{props.children}</Layout>;
};

export default WithAuth;
