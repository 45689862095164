import { useContext } from 'react';
import UserContext from '../../Context/user-context';
import styles from './FAQ.module.less';

const FAQ = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  return (
    <div>
      {loading ? null : data ? (
        <>
          {(data.program[0].name === "Global Tech Initiative Scholar (6 months)" ||
    data.program[0].name === "Global Tech Initiative Scholar (4 months)") && (
            <div>
              Some common FAQs can be found <a href={
          data.program[0].name === "Global Tech Initiative Scholar (6 months)"
            ? "https://drive.google.com/file/d/1dKA46OWbh6rTZKjsxSZ_9jz_b3ifHiNj/view"
            : "https://drive.google.com/file/d/1-twUBJ3seRkJPsCBm4H6G5v1VXYWCgKa/view"
        } target="_blank" rel="noopener noreferrer">
                here
              </a>
            </div>
          )}
          {data.program[0].name === "GTI Feynman Fellow" && (
            <div>
              Some common FAQs can be found <a href="https://drive.google.com/file/d/1ny3PFKoUw0os5fUDU-me5yJ0tnD-2Bau/view" target="_blank" rel="noopener noreferrer">here</a>
            </div>
          )}
          {data.program[0].name === "GGI Tech Policy" && (
            <div>
              Some common FAQs can be found <a href="https://drive.google.com/file/d/12UcB1Ku6sanXBzob7YQN3fzsdvo0bVJ2/view" target="_blank" rel="noopener noreferrer">here</a>
            </div>
          )}
          {data.program[0].name === "GGI Executives 6 months" && (
            <div>
              Some common FAQs can be found <a href="https://drive.google.com/file/d/1eWF_EiFA8spS8-1nBBkkdPTmEPt-nwbZ/view" target="_blank" rel="noopener noreferrer">here</a>
            </div>
          )}
          {data.program[0].name !== "Global Tech Initiative Scholar (6 months)" &&
            data.program[0].name !== "Global Tech Initiative Scholar (4 months)" &&
            data.program[0].name !== "GTI Feynman Fellow" && (
            <ol className={styles.container}>
              {data.program[0].FAQs.map((item, index) => (
                <li key={index}>
                  {data.program[0].name === "ALT IIT Scholar" ? (
                    <a href="your-link-goes-here" target="_blank" rel="noopener noreferrer">
                      Link to ALT IIT Scholar
                    </a>
                  ) : (
                    <>
                      <p className={styles.question}>
                        {item.question}
                      </p>
                      {item.answer.map((answerItem, answerIndex) => (
                        <p key={answerIndex} className={styles.answer}>
                          {answerItem}
                        </p>
                      ))}
                    </>
                  )}
                </li>
              ))}
            </ol>
          )}
        </>
      ) : null}
    </div>
  );
};

export default FAQ;
