import { useContext } from 'react';
import UserContext from '../../Context/user-context';
import styles from './AdditionalLR.module.less';

const AdditionalLR = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  let additional;
  if (data) {
    additional = data.program[0].additional;
  }
  // console.log(data);

  return (
    <div className='container'>
      {loading ? null : data ? (
        <>
        <div className='box'>
          <ul className={styles.container}>
          {data.program[0].additional.map((item, index) => (
            <li key={index}>
              <h3 className='box-title'>{item.title}</h3>
              {item.list.map((item, index) => (
                <p key={item} dangerouslySetInnerHTML={{ __html: item }}>
                  
                </p>
              ))}
            </li>
         
          ))}
        </ul>
        </div>
          
        </>
      ) : null}
    </div>
  );
};

export default AdditionalLR;